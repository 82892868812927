<template>
    <section class="repair-management">
        <div class="top-bar bg-white align-center">
            <el-input v-model="formSearch.keyword" style="margin: 5px; width: 250px" size="small" placeholder="请输入搜索"/>
            <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
            <el-button size="small" @click="handleReset" style="margin: 0 7px;">重置</el-button>
        </div>
        <r-e-table ref="tableRef" :dataRequest="getLockInstallList" :columns="tableColumn" :query="formSearch"
                   :height="790">
            <el-table-column slot="toolbar" label="操作" width="100">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="ckHandle(row)">查看附件</span>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>
        <r-e-dialog title="查看附件" class="layer-contract-detail" :visible.sync="dialogVisible" top="10vh">
            <div class="flex" style="height: 180px;overflow:scroll;">
                <div v-for="(item,index) in imgList" :key="index">
                    <div class="flex justify-center"
                        style="background: #f5f7fa;width: 225px;height: 150px;border: 1px solid #F1F1F3;border-radius: 5px;margin: 0 10px">
                        <el-image :src="item.url" :preview-src-list="[item.url]" class="flex align-center">
                            <div slot="error" style="font-size: 30px;">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                            <div slot="placeholder">加载中...</div>
                        </el-image>
                    </div>
                </div>
            </div>
        </r-e-dialog>
    </section>
</template>

<script>
import {tableColumn} from "./data";
import {repairManagement, repairInfo} from "@/api/repair-management";

export default {
    name: "repair-management",
    data() {
        return {
            tableColumn,
            formSearch: {
                keyword: null,
                order: 'desc',
                column: 'createTime',
            },
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
            imgList: [],
            dialogVisible: false,
        };
    },
    components: {},
    methods: {
        handleSearch(isSearch = true) {
            // 搜索
            this.$refs["tableRef"].getTableData(isSearch);
        },
        // 重置按钮
        handleReset() {
            this.formSearch.keyword = null;
            this.handleSearch();
        },
        // 维修列表
        getLockInstallList(params) {
            return repairManagement(params);
        },
        // 查看附件
        ckHandle(data) {
            let that = this;
            const {repairGuid} = data.innerMap;
            const loadingOptions = that.loadingOptions;
            let loading = that.$loading({...loadingOptions});
            repairInfo({repairGuid}).then(res => {
                const {list} = res;
                that.imgList = list;
                that.dialogVisible = true;
            }).finally(() => loading.close());
        }
    },
    mounted() {
    },
    watch: {}
}
</script>

<style lang="scss" scoped>
.repair-management {
    padding: VH(15px) VW(15px);

    .top-bar {
        display: flex;
        margin: VH(10px) 0 VH(25px);
        padding: VH(15px) VW(15px);
        //justify-content: center;
    }

    /deep/ .r-e-table {
        .el-table .cell {
            //white-space: nowrap;
            text-align: center;
        }
    }
}
</style>